<template>
  <div class="videodetail">
    <van-nav-bar
      title="使用教学"
      left-arrow
      @click-left="onClickLeft"
    />

    <div>
      <video
        id="video"
        :height="screenWidth/315*230"
        width="100%"
        webkit-playsinline="true"
        x5-video-player-type="h5"
        x5-video-orientation="portraint"
        controls
        :poster="info.thumb"
        :src="info.video"
      ></video>
    </div>
  </div>
</template>
<script>
export default {
  name: "videoDetail",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      shareid: 0,
      merchid: 0,
      id: 0,
      info: {},
      wxConfig: {
        debug: false, // 开启调试模式 true false
        appId: "wxc465682b705715e5",
        jsApiList: [
          "updateAppMessageShareData",
          "updateTimelineShareData",
          "onMenuShareTimeline",
          "onMenuShareAppMessage"
        ], // 必填，需要使用的JS接口列表
        timestamp: 1585098244, // 必填，生成签名的时间戳
        nonceStr: "dfbfwkm1egc", // 必填，生成签名的随机串
        signature: "d8caa8a88d588a8dc5379dbfbde5d875886baed5" // 必填，签名
      },
    }
  },
  mounted() {
    this.id = this.$route.query.id ? this.$route.query.id : 0;
    this.shareid = this.$route.query.shareid ? this.$route.query.shareid : 0;
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.getinfo();
  },
  methods: {
    getinfo() {
      let that = null;
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + 'web/videos/get_one',
          that.$qs.stringify({
            id: that.id
          })
        )
        .then(res => {
          if (res.data.code == 100000) {
            that.info = res.data.data
            console.log(that.info, "that.infothat.infothat.infothat.info");
          }else {
            that.$toast(res.data.msg ? res.data.msg : '暂无视频');
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    onClickLeft() {
      this.$router.back();
    }
  },
}
</script>
<style lang="less">
.videodetail {
  width: 100%;
  min-height: 100%;
  background: #fff;
  overflow: hidden;
  position: relative;
  z-index: 2;
  .van-nav-bar .van-icon {
    color: #333;
  }
}
</style>